import React from "react"

import Layout from "../../components/layout"

import * as styles from '../../styles/pages/about-art.module.css'

import image_banner from '../../images/about/art_banner.jpg'
import image_banner_beauty from '../../images/about/art_banner_beauty.jpg'
import image_banner_cement from '../../images/about/art_banner_cement.jpg'


const AboutArtOfConcretePage = () => (
  <Layout activePath='about' fullPath='about/art-of-concrete' pageTitle="Art of Concrete">
  	<div className="bg-cement-tile">
  		<br/><br/>
  		<img className={styles.banner} src={image_banner} alt="" />
	  	<div className="margin-content">
			<h2 className="font-size-huge font-style-sectiontitle">Art of Concrete</h2>

			<p>There is beauty in bareness.</p>

			<p>After a long and tiring day of feasting your eyes with extremities of your surroundings, it would be nice to cleanse under the warmth of a cozy space. As you soak into the fragrance of your bubbly bath, you look around and see your barely finished walls and was reminded again why you just let it be. Setting aside the obvious reasons of it being cheaper than tiles or marbles, you love the aesthetics and how it's so easy for you to look at.</p>

			<p>You appreciate its simplicity and you reiterate to yourself, less is always more.</p>

			<p>And it is where the recent trends are heading –minimalism. By reducing all the excess details, it aims to attain its truest, barest form. From fashion and cosmetics to design and architecture, it looks like there's no stopping this trend from emerging into every scene.</p>

			<p>Just along Metro Manila, the number of local establishments venturing on minimalistic interiors are myriad. Each one maximizing the flexibility of an unfinished concrete not only to cut costs but also to be and to stay on trend. Minimalism has had its time years ago and, thankfully, it's finally reclaiming its throne, giving way to the comeback of industrial finishes.</p>
		</div>
  		<img className={styles.banner} src={image_banner_beauty} alt="" />
	  	<div className="margin-content">
	  		<span className={"font-style-bold"}>BEAUTY OF ENGINEERING</span>
	  		<br/><br/>

			<p>As expressed in the art of a bare concrete.</p>

			<p>Tracing back the history, Europeans first introduced industrial finish to the world during a major financial crisis that almost cost them their fortune. To save money, they decided to ditch the post-process of concrete finishing and invest it on furniture when building a new structure. This movement escalated and turned out to be one of the go-to design trend of every era.</p>

			<p>Unfinished concretes, wood-and-steel furniture, and exposed piping are just few of the common elements that are usually present when designing an industrial chic interior. The rawness of a bare concrete adds a flair of sophistication while still maintaining its cozy atmosphere to any place and is the perfect backdrop to highlight centerpieces. Imagine that bright orange chair with wooden legs your grandma gave you as a birthday gift, how it stands out against a wall sans the paint color. What a sight for sore eyes. Most designers are opting for this kind of industrial chic style and fair to say, it is beneficial for both ends since it gives the clients the option to invest on the furnishing and not worry about their walls being out of style.</p>

			<p>Aside from the sleek and warm ambience it brings, many find this trend eminently practical. In this economy, home and business owners alike are going for what costs them less without compromising quality –industrial finish gives just that. Since you won't need to spend on the post-process materials, it already saves you a lot. Which in turn, once you get bored with the current one, can be a blank canvas to have fun with.</p>

			<p>Cement floors are also booming essentially due to its strength and versatility. Apart from being the inexpensive alternative to tiles, a concrete floor is much easier and quicker to build. Furthermore, because of its natural damp resistant surface, it's easier to clean and only requires minimum effort on maintenance. Since floors are normally subjected to high traffic, it's best to choose a product that can give you the protection you need. Konstrukt™ Trafficshield™ line offers its customers professional surface preparation and concrete floor finishing bearing the same quality BOYSEN<span className='registeredicon'>&reg;</span> preserved throughout the years. Its K-441 Floor Screed can fix wider imperfections and can outlast an ordinary cement, a perfect solution to the demanding needs of the industry. Plus, it's aesthetically entrancing.</p>
		</div>
  		<img className={styles.banner} src={image_banner_cement} alt="" />
	  	<div className="margin-content">
			<p>Of course, great results can only be achieved through great preparation, whether it be for walls or flooring. Smoothen the area you'll work on by skim coating. Make sure to do what needs to be done first before proceeding to the next steps. After all, industrial finish is all about finesse and refinement. And by doing so, it lessens the possibility of major renovations come years after. One more thing to remember when trying to rock an elegant industrial chic space, the color of the cement used is a major issue. Sure, it's all concrete but each interior has a distinct texture and color, so plan ahead and decide on what you want your space to radiate.</p>

			<p>Industrial minimalism might die down for the following years to come, like anything in this world are wont to do, but one thing's for sure, when it goes crashing down, it comes back every time.</p>

			<p>It'll never go out of style.&nbsp;<span className="color-text-primary">&#9607;</span></p>
			<br/>

	  	</div>
	  </div>
  </Layout>
)

export default AboutArtOfConcretePage
